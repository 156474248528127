<template>
  <v-dialog v-model="value" persistent max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text"> Update Tracker</span>
      </v-card-title>
      <v-card-text class="pt-3 my-4">
        <v-row>
          <v-col>
            <ac-input v-model="company.name" label="Company Name" outlined readonly></ac-input>
          </v-col>
          <v-col>
            <ac-input v-model="company.status" label="Company Status" outlined readonly></ac-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ac-select
              label="Main Contact"
              v-model="company.mainContact"
              :items="contactOptions"
              class="company-select"
            />
          </v-col>
          <v-col>
            <ac-select
              label="Support Contact"
              v-model="company.supportContact"
              :items="contactOptions"
              class="company-select"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="company.reportRequired"
              label="Report Required"
              class="mt-0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
        <ac-button title="Submit" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'

import { mdiCalendar } from '@mdi/js'

export default {
  name: 'EditReportTrackerModdal',
  components: {
    AcButton,
    AcInput,
    AcSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiCalendar,
      },
      company: {
        name: 'AAAA Alpha Cypress',
        status: 'Active',
        mainContact: 'Tepper Gmail',
        supportContact: 'Head Staff 1',
        reportRequired: true,
      },
      contactOptions: ['Tepper Gmail', 'Head Staff 1', 'Intern', 'Rocky'],
      date: null,
      menu: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  max-width: 600px;
}
</style>
