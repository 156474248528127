<template>
  <div>
    <ac-module-heading>Report Tracker</ac-module-heading>
    <v-card>
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="5">
            <div class="d-flex">
              <ac-select
                label="Company"
                v-model="selectedCompanies"
                :items="companies"
                class="company-select me-3"
                multiple
                chips
                clearable
              />
              <ac-select
                label="Year"
                v-model="selectedYear"
                :items="years"
                class="company-select me-3"
              />
            </div>
          </v-col>
          <v-col cols="auto">
            <v-menu
              v-model="showItemsMenu"
              :close-on-content-click="false"
              :nudge-width="100"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on"> Show Items</v-btn>
              </template>
              <v-card>
                <v-card-text class="py-2">
                  <v-switch label="Inactive Companies" class="mt-2" dense hide-details> </v-switch>
                  <v-switch label="Inactive Staffs" class="mt-2" dense hide-details> </v-switch>
                  <v-switch
                    label="Companies with Compliance Subscription"
                    class="my-2"
                    dense
                    hide-details
                  >
                  </v-switch>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider> </v-divider>
      <v-card-text>
        <v-card>
          <v-expansion-panels class="company-info" multiple>
            <v-expansion-panel v-for="(company, i) in companyDetais" :key="i" class="mt-2">
              <v-expansion-panel-header class="align-start">
                <v-row>
                  <v-col class="cursor-default">
                    <div class="heading d-flex">
                      <div class="name text-lg me-9">{{ company.compnayName }}</div>
                      <v-chip label color="success"> {{ company.status }} </v-chip>
                    </div>
                    <div class="contacts my-2">
                      <ac-label class="label rounded elevation-3 mr-4"> Main Contact </ac-label>
                      <div class="value">{{ company.mainContact }}</div>
                    </div>
                    <div class="contacts">
                      <ac-label class="label rounded elevation-3 mr-4">Support</ac-label>
                      <div class="value">{{ company.support }}</div>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-flex justify-space-between align-center">
                      <v-switch
                        v-model="company.reportRequired"
                        label="Report Required"
                        class="mt-0"
                        hide-details
                        @click.stop
                      ></v-switch>
                      <v-tooltip v-if="!company.reportRequired" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <ac-button
                              title="Update Tracker"
                              small
                              disabled
                              :icon="icon.mdiPencil"
                            ></ac-button
                          ></span>
                        </template>
                        <span class="text-caption">
                          Enable <strong>Report Required</strong> first
                        </span>
                      </v-tooltip>

                      <ac-button
                        v-else
                        title="Update Tracker"
                        small
                        :icon="icon.mdiPencil"
                        @click="showEditModal = true"
                      ></ac-button>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-data-table
                  :headers="tableHeader"
                  :items="company.tableData"
                  class="text-no-wrap"
                  hide-default-footer
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-card-text>
    </v-card>
    <edit-report-tracker-modal v-model="showEditModal" />
  </div>
</template>

<script>
import AcSelect from '@/components/AcSelect.vue'
import AcButton from '@/components/AcButton.vue'
import EditReportTrackerModal from '@/components/ComplianceReportTracker/EditReportTrackerModal.vue'
import { mdiPencil } from '@mdi/js'

export default {
  components: {
    AcSelect,
    AcButton,
    EditReportTrackerModal,
  },
  data: () => ({
    icon: {
      mdiPencil,
    },
    selectedCompanies: null,
    companies: ['Company 1', 'Company 2', 'Company 3'],
    selectedYear: null,
    years: [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014],
    showItemsMenu: false,
    reportRequired: false,
    loading: false,
    showEditModal: false,
    tableHeader: [
      { text: '', value: 'id' },
      { text: 'JANUARY', value: 'january' },
      { text: 'FEBRUARY', value: 'february' },
      { text: 'MARCH', value: 'march' },
      { text: 'APRIL', value: 'april' },
      { text: 'MAY', value: 'may' },
      { text: 'JUNE', value: 'june' },
      { text: 'JULY', value: 'july' },
      { text: 'AUGUST', value: 'august' },
      { text: 'SEPTEMBER', value: 'september' },
      { text: 'OCTOBER', value: 'october' },
      { text: 'NOVEMBER', value: 'november' },
      { text: 'DECEMBER', value: 'december' },
    ],
    companyDetais: [
      {
        compnayName: 'Alpha Investment Company',
        status: 'Active',
        reportRequired: true,
        mainContact: 'Tepper Gmail',
        support: 'Lorem ipsum dolor sit amet consectetur.',
        tableData: [
          {
            id: 'Report Status',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
          {
            id: 'Version Sent To Client',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
          {
            id: 'Sent By',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
        ],
      },
      {
        compnayName: 'Dreamteam AM Ltd',
        status: 'Active',
        reportRequired: false,
        mainContact: '-',
        support: '-',
        tableData: [
          {
            id: 'Report Status',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
          {
            id: 'Version Sent To Client',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
          {
            id: 'Sent By',
            january: '-',
            february: '-',
            march: '-',
            april: '-',
            may: '-',
            june: '-',
            july: '-',
            august: '-',
            september: '-',
            october: '-',
            november: '-',
            december: '-',
          },
        ],
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.company-info {
  .name {
    width: 40%;
    word-break: break-all;
  }
}
.contacts {
  display: flex;
  align-items: center;
  .label {
    width: 8rem;
  }
}
</style>
