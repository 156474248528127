import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VCardTitle,{staticClass:"bg-gradient-primary-2"},[_c('span',{staticClass:"white--text"},[_vm._v(" Update Tracker")])]),_c(VCardText,{staticClass:"pt-3 my-4"},[_c(VRow,[_c(VCol,[_c('ac-input',{attrs:{"label":"Company Name","outlined":"","readonly":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})],1),_c(VCol,[_c('ac-input',{attrs:{"label":"Company Status","outlined":"","readonly":""},model:{value:(_vm.company.status),callback:function ($$v) {_vm.$set(_vm.company, "status", $$v)},expression:"company.status"}})],1)],1),_c(VRow,[_c(VCol,[_c('ac-select',{staticClass:"company-select",attrs:{"label":"Main Contact","items":_vm.contactOptions},model:{value:(_vm.company.mainContact),callback:function ($$v) {_vm.$set(_vm.company, "mainContact", $$v)},expression:"company.mainContact"}})],1),_c(VCol,[_c('ac-select',{staticClass:"company-select",attrs:{"label":"Support Contact","items":_vm.contactOptions},model:{value:(_vm.company.supportContact),callback:function ($$v) {_vm.$set(_vm.company, "supportContact", $$v)},expression:"company.supportContact"}})],1)],1),_c(VRow,[_c(VCol,[_c(VSwitch,{staticClass:"mt-0",attrs:{"label":"Report Required","hide-details":""},model:{value:(_vm.company.reportRequired),callback:function ($$v) {_vm.$set(_vm.company, "reportRequired", $$v)},expression:"company.reportRequired"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('ac-button',{attrs:{"title":"Close","color":"error","outlined":""},on:{"click":_vm.onClose}}),_c('ac-button',{attrs:{"title":"Submit","color":"success"},on:{"click":_vm.onClose}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }